#yotpo-testimonials-custom-tab {
	min-height: 100vh; /* to minimize layout shift */
}

$yotpoTablet: 541px;

.yotpoReviews {
	.bottom-line-items {
		text-align: center !important;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}

	.reviews-qa-labels-container {
		display: inline-block !important;
		position: inherit !important;
		float: none !important;

		@include breakpoint($yotpoTablet, max) {
			margin-left: 0 !important;
			width: 100%;
		}

		@include breakpoint($yotpoTablet, min) {
			margin-left: spacer(md);
		}
	}

	.yotpo-icon-star {
		@include breakpoint($yotpoTablet, max) {
			margin-top: spacer(base) !important;
		}

		@include breakpoint($yotpoTablet, min) {
			margin-top: 0 !important;
		}
	}

	.avg-score {
		height: auto !important;
		width: auto !important;
		line-height: 1 !important;
		padding-right: spacer(xs);
		font-family: $fontFamily-serif !important;
		color: color(text, base) !important;
		font-weight: 400 !important;
		font-size: set-fontSize(32) !important;

		@include breakpoint($yotpoTablet, min) {
			padding-top: spacer(3xs);
		}
	}
}