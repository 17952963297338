$env: production;
// // Settings
@import './settings.styles';
@import '@saatva-bits/pattern-library.styles.core/theme/saatva/settings.scss';
@import '@saatva-bits/pattern-library.styles.core/settings.styles.scss';
@import '@saatva-bits/pattern-library.styles.core/tools.styles.scss';
@import '@saatva-bits/pattern-library.styles.core/theme/saatva/component-configs.scss';

@import './settings/settings.theme';

// // Pattern Library Styles
@import '@saatva-bits/pattern-library.styles.core/core.scss';

// // Saatva Theme Typography
@import '@saatva-bits/pattern-library.styles.core/theme/saatva/typography.scss';

// @import './base.styles';

// // Utilities (must be called last)
@import '@saatva-bits/pattern-library.styles.core/utils.styles.scss';

@import './move-to-pl.styles.scss';

// overriding Yotpo widget styles (requires global css)
@import './yotpo.styles.scss';

@import '@saatva-bits/pattern-library.utils.chat/chatOverride.scss';

button {
    color: color(text, base);
}

.container--lg-extended .container {
    @include breakpoint(lg) {
        max-width: 1500px;
        padding: 0 1.25rem;
    }
}
