// TODO: evaluate these styles to be cleaned up and moved to pattern library

$imgixUrls: (
    'local': 'https://saatva-qa.imgix.net',
    'dev': 'https://saatva-qa.imgix.net',
    'qa': 'https://saatva-qa.imgix.net',
    'stage': 'https://saatva-stage.imgix.net',
    'production': 'https://saatva.imgix.net'
);

$imgixDomain: map-get($imgixUrls, $env);

@mixin responsive-dpi-imgix($folder, $name, $mobileWidth, $tabletWidth, $desktopWidth, $isUniversal: false) {
    $mobilePrefix: if($isUniversal, 'u-', 'm-');
    $tabletPrefix: if($isUniversal, 'u-', 't-');
    $desktopPrefix: if($isUniversal, 'u-', 'd-');

    background-image: url('#{$imgixDomain}#{$folder}/#{$mobilePrefix}#{$name}.jpg?dpr=1&auto=format&w=#{$mobileWidth}');

    @include breakpoint(md) {
        background-image: url('#{$imgixDomain}#{$folder}/#{$tabletPrefix}#{$name}.jpg?dpr=1&auto=format&w=#{$tabletWidth}');
    }

    @include breakpoint(lg) {
        background-image: url('#{$imgixDomain}#{$folder}/#{$desktopPrefix}#{$name}.jpg?dpr=1&auto=format&w=#{$desktopWidth}');
    }

    @media screen and (min-resolution: 192dpi) {
        background-image: url('#{$imgixDomain}#{$folder}/#{$mobilePrefix}#{$name}.jpg?dpr=2&auto=format&w=#{$mobileWidth}');

        @include breakpoint(md) {
            background-image: url('#{$imgixDomain}#{$folder}/#{$tabletPrefix}#{$name}.jpg?dpr=2&auto=format&w=#{$tabletWidth}');
        }

        @include breakpoint(lg) {
            background-image: url('#{$imgixDomain}#{$folder}/#{$desktopPrefix}#{$name}.jpg?dpr=2&auto=format&w=#{$desktopWidth}');
        }
    }
}

@function colorHexOpacity($color, $opacityNum) {
    @return #{$color}#{$opacityNum};
}
